@import "../../../assets/scss/variables.scss";

.liner {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    border: 1px solid $color-primary;
    padding: 20px;
    margin-bottom: 40px;
    width: 30%;
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    transition: .4s;
    &:hover {
      opacity: .5;
    }
    &-figure {
      padding: 0;
      margin: 0 0 10px;
      border: 1px solid #ccc;
    }
    &-img {
      width: 100%;
      vertical-align: top;
    }
    &-icon {
      &-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        background: $color-white;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &:nth-child(3n+2) {
      margin-left: 5%;
      margin-right: 5%;
    }
  }
}

@include mq(sm) {
	.liner {
    &-wrapper {
      display: block;
    }
    &-item {
      width: 100%;
      display: inline-block;
      &:nth-child(3n+2) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}