@import "../../../assets/scss/variables.scss";

.contents-box {
  &-blue {
    background-color: $color-primary-light;
  }
  &-inner {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }
}