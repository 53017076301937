@import "../../../assets/scss/variables.scss";

.form {
  &-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ccc;
  }
  &-inner {
    width: 80%;
    display: flex;
    align-items: top;
    margin: 0;
    padding: 0;
  }
  &-item {
    margin-right: 10px;
    width: 50%;
    &:last-child {
      margin-right: 0;
    }
  }
  &-label {
    font-size: 16px;
    margin: 0 0 10px;
  }
  &-body {
    margin: 0;
    &-fromto {
      display: flex;
      align-items: center;
    }
    &-input {
      padding: 5px 12px;
      min-height: 40px;
      border-radius: 6px;
      border: 1px solid #ccc;
      width: 100%;
    }
  }
  &-child {
    padding: 0 5px;
  }
  &-btn {
    min-height: 40px;
    margin-left: 1%;
    width: 19%;
  }
}

@include mq(md) {
	.form {
    &-wrapper {
      flex-wrap: wrap;
    }
    &-inner {
      width: 100%;
      flex-wrap: wrap;
    }
    &-item {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
    &-btn {
      margin-left: 0;
      width: 100%;
    }
  }
}