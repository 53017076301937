@import "../../../assets/scss/variables.scss";

.pagination {
  display: flex;
  justify-content: center;
  margin: 60px 0 0;
  &-btn {
    background: none;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    svg {
      font-size: 14px;
      margin: 0 4px;
    }
    &:first-child {
      margin: 0 20px 0 0;
    }
    &:last-child {
      margin: 0 0 0 20px;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  &-list {
    li {
      button {
        &:global(.Mui-selected) {
        }
      }
    }
  }
}