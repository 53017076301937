@import "../../../assets/scss/variables.scss";

.path {
  &-wrapper {
    background: $color-primary-light;
    display: flex;
    align-items: center;
  }
  &-item {
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    margin-right: 4px;
    &:hover {
      text-decoration: underline;
      opacity: .7;
    }
    &::after {
      content: ">";
      padding-left: 4px;
    }
    &:last-child {
      &::after {
        content: "";
        padding-left: 0;
      }
    }
  }
}