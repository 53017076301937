/* width */

$sm-width: 599px;
$md-width: 959px;
$lg-width: 1079px;
$xl-width: 1919px;
$hd-width: 934px;

/* transion time */

$short-time: .4s;
$medium-time: .6s;
$long-time: .8s;

/* breakpoints */

$breakpoints: (
  'sm': 'screen and (max-width: '$sm-width')',
  'md': 'screen and (max-width: '$md-width')',
  'lg': 'screen and (max-width: '$lg-width')',
  'xl': 'screen and (max-width: '$xl-width')',
  'hd': 'screen and (max-width: '$hd-width')',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}


/* color */
$color-primary: #001d55;
$color-primary-light: #ecf2ff;
$color-white: #fff;
$color-gray: #ccc;