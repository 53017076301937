@import "../../assets/scss/variables.scss";

.back {
  &-btn {
    &-sp {
      display: none;
    }
  }
}

@include mq(hd) {
	.back {
    &-btn {
      &-sp {
        display: flex;
      }
      &-pc {
        display: none;
      }
    }
  }
}