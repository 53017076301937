@import "../../../assets/scss/variables.scss";


.page {
  &-cms {
    :global( > *) {
      font-size: 16px;
    }
    :global(h1) {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      margin: 0 0 20px;
      font-weight: bold;
    }
    :global(h2) ,
    :global(h3) ,
    :global(h4) ,
    :global(h5) ,
    :global(h6) {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 25px;
      font-weight: normal;
      margin: 0 0 20px;
      font-weight: bold;
    }
    :global(img) {
      width: 100%;
      margin: 0 0 40px;
    }
    :global(p) {
      font-size: 16px;
      line-height: 2;
      margin: 0 0 10px;
      &:last-child {
        margin: 0;
      }
    }
    :global(ul) ,
    :global(ol) {
      margin: 0 0 10px;
      padding: 0;
      li {
        font-size: 16px;
        line-height: 2.25;
        list-style-type: none;
      }
    }
    :global(table) {
      border-top: 1px solid #ccc;
      border-collapse: collapse;
      width: 100% !important;
      tr {
        td:first-child {
          width: 20%;
          font-weight: bold;
        }
      }
      th, td {
        border-bottom: 1px solid #ccc;
        padding: 10px;
        margin: 0;
        line-height: 1.9;
        box-sizing: border-box;
        p {
          margin: 0;
          padding: 0;
          line-height: 1;
        }
      }
    }
  }
}


@include mq(hd) {
	.page {
    &-cms {
      :global(table) {
        tr {
          td:first-child {
            border: none;
            width: 100%;
          }
        }
        th, td {
          padding: 5px 10px 0;
          display: block;
          width: 100%;
        }
        th {
          text-align: left;
          padding: 5px 10px 0;
          border: none;
        }
      }
    }
  }
}