@import "../../assets/scss/variables.scss";

.comment-liner {
  &-btn {
    width: 49%;
    cursor: pointer;
    &-wrapper {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      &-center {
        justify-content: center;
      }
    }
  }
}

@include mq(md) {
	.comment-liner {
    &-btn {
      width: 100%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &-wrapper {
        width: 100%;
        display: block;
        &-center {
          justify-content: center;
        }
      }
    }
  }
}