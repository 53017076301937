@use "./variables.scss";

@import url(https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css);

body {
		font-family: YakuHanJP,
		"Helvetica Neue",
		Arial,
		"Hiragino Kaku Gothic ProN",
		Meiryo,
		sans-serif;
}

a {
	color: rgba(0, 0, 0, 0.87);
	text-decoration: underline;
	&:hover {
		text-decoration: none;
		opacity: .7;
	}
}