@import "../../../assets/scss/variables.scss";

.content-title {
  &-inner {
    padding: 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-text {
    color: $color-primary;
    font-weight: bold;
  }
  &-helper {
    max-width: 1080px;
    margin: 0 auto;
    padding: 4px 0;
    &-btn {
      padding: 4px 0;
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    &:hover {
      opacity: .7;
    }
  }
}

.page-title {
  background-position: right;
  background-repeat: no-repeat;
  background-color: #000;
  height: 300px;
  display: flex;
  align-items: center;
  position: relative;
  &-inner {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 10px 0 0;
    z-index: 2;
  }
  &-text {
    font-family: serif;
    font-weight: bold;
    text-shadow: 1px 1px 0 #001d55, -1px 1px 0 #001d55, 1px -1px 0 #001d55, -1px -1px 0 #001d55;
    z-index: 2;
  }
  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) calc(100% - 658px), rgba(0,0,0,0) calc(100% - 329px), rgba(0,0,0,0) 100%);
  }
}

@include mq(md) {
  .content-title {
    &-text {
      font-size: 20px;
    }
  }
	.page-title {
    background-position: right bottom;
    background-size: contain;
    height: 150px;
    &-text {
      font-family: serif;
      font-weight: bold;
    }
    &-overlay {
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) calc(100% - 232px), rgba(0,0,0,0) calc(100% - 116px), rgba(0,0,0,0) 100%);
    }
  }
}