@import "../../../assets/scss/variables.scss";

.footer {
  &-wrapper {
    background: $color-primary;
  }
}

.sp-menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  background-color: $color-primary-light;
  z-index: 998;
  padding: 82px 16px 30px;
  transition: top .6s;
  display: none;
  &-active {
    top: 0;
  }
}


@include mq(hd) {
	.sp-menu {
    display: block;
  }
}