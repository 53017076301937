@import "../../assets/scss/variables.scss";

.hero {
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  position: relative;
  &-img {
    width: 100%;
  }
  &-inner {
    width: 100%;
    max-width: 1080px;
    margin: 68px auto 0;
    padding-top: 722px;
  }
  &-text {
    animation-duration: 1.5s;
    animation-name: fadeInUp;
    position: absolute;
    bottom: 56%;
    left: 30px;
    font-size: 3vw;
    font-family: serif;
    z-index: 1;
    color: #fff;
    font-weight: bold;
    line-height: 1.6;
    text-shadow: 1px 1px 0 $color-primary,
               -1px 1px 0 $color-primary,
               1px -1px 0 $color-primary,
               -1px -1px 0 $color-primary;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@include mq(sm) {
  .hero {
    &-inner {
      padding-top: 136.231884%;
    }
    &-text {
      font-size: 5vw;
    }
  }
}

@include mq(lg) {
  .hero {
    &-inner {
      padding-top: 52.855051%;
    }
  }
}


@include mq(sm) {
	.hero {
    &-text {
      bottom: 30%;
    }
  }
}